import React, { useState, useEffect } from 'react'
import { useContractRead, useContractWrite, useAccount, useContract } from 'wagmi'
import { parseEther } from 'ethers/lib/utils'
import ABI from "../abi"
import Swal from 'sweetalert2'
import { contractAddress } from '../const'

let lastHash, lastHash3, lastHash5: any = undefined

const MintView = () => {
    const { data: accountData } = useAccount()

    const { data: mintData, write: writeMint} = useContractWrite(
        {
            addressOrName: contractAddress,
            contractInterface: ABI,
        },
        'mintWitch',
        {
            args: [{value: parseEther("0.07")}]
        }
    )

    const { data: mint3Data, write: writeMint3} = useContractWrite(
        {
            addressOrName: contractAddress,
            contractInterface: ABI,
        },
        'mint3Witches',
        {
            args: [{value: parseEther("0.2")}]
        }
    )

    const { data: mint5Data, write: writeMint5} = useContractWrite(
        {
            addressOrName: contractAddress,
            contractInterface: ABI,
        },
        'mint5Witches',
        {
            args: [{value: parseEther("0.3")}]
        }
    )

    if (mintData && mintData.hash !== lastHash) {
        lastHash = mintData.hash
        Swal.fire("Success", `Your witch is being summoned.`)
    }

    if (mint3Data && mint3Data.hash !== lastHash3) {
        lastHash = mint3Data.hash
        Swal.fire("Success", `Your three witches are being summoned.`)
    }

    if (mint5Data && mint5Data.hash !== lastHash5) {
        lastHash = mint5Data.hash
        Swal.fire("Success", `Your five witches are being summoned.`)
    }

    if(!accountData) {
        return(
            <div className="my-2 text-xl w-6/12 mx-auto text-center">
                Connect to Wallet Above to Mint
            </div>
        )
    }

    return(
        <div className="w-6/12 mx-auto">
            <div className="w-[33%] inline-block text-center">
                <div className="text-2xl">0.07 ETH</div>
                <button
                    className="border-4 border-[#FBF0DC] hover:border-white text-2xl lg:text-xl font-bold py-4 px-6 m-2 rounded-full"
                    onClick={() => writeMint()}>MINT ONE</button>
            </div>
            <div className="w-[33%] inline-block text-center">
                <div className="text-2xl">0.2 ETH</div>
                <button
                    className="border-4 border-[#FBF0DC] hover:border-white text-2xl lg:text-xl font-bold py-4 px-6 m-2 rounded-full"
                    onClick={() => writeMint3()}>MINT THREE</button>
            </div>
            <div className="w-[33%] inline-block text-center">
                <div className="text-2xl">0.3 ETH</div>
                <button
                    className="border-4 border-[#FBF0DC] hover:border-white text-2xl lg:text-xl font-bold py-4 px-6 m-2 rounded-full"
                    onClick={() => writeMint5()}>MINT FIVE</button>
            </div>
        </div>
    )
}

export default MintView