import React from 'react'

import candle1 from '../img/candle-1.png'
import candle2 from '../img/candle-2.png'
import candle3 from '../img/candle-3.png'
import candle4 from '../img/candle-4.png'

import candle1Lit from '../img/candle-1-lit.png'
import candle2Lit from '../img/candle-2-lit.png'
import candle3Lit from '../img/candle-3-lit.png'
import candle4Lit from '../img/candle-4-lit.png'

const NavCandles = () => {
    return(
        <div className="mt-24 mx-auto w-6/12">

            <div className="w-[25%] inline-block text-center cursor-pointer" onMouseOver={() => {document.getElementById("candle1").src = candle1Lit}} onMouseOut={() => {document.getElementById("candle1").src = candle1}}>
                <a href="#path">
                    <img src={candle1} alt="Candle 1" id="candle1" />
                    THE PATH
                </a>
            </div>

            <div className="w-[25%] inline-block text-center cursor-pointer" onMouseOver={() => {document.getElementById("candle2").src = candle2Lit}} onMouseOut={() => {document.getElementById("candle2").src = candle2}}>
                <a href="#about">
                    <img src={candle2} alt="Candle 2" id="candle2" />
                    ABOUT
                </a>
            </div>

            <div className="w-[25%] inline-block text-center cursor-pointer"  onMouseOver={() => {document.getElementById("candle3").src = candle3Lit}} onMouseOut={() => {document.getElementById("candle3").src = candle3}}>
                <a href="#acolytes">
                    <img src={candle3} alt="Candle 3" id="candle3" />
                    THE ACOLYTES
                </a>
            </div>

            <div className="w-[25%] inline-block text-center cursor-pointer"  onMouseOver={() => {document.getElementById("candle4").src = candle4Lit}} onMouseOut={() => {document.getElementById("candle4").src = candle4}}>
                <a href="#lore">
                    <img src={candle4} alt="Candle 4" id="candle4" />
                    LORE
                </a>
            </div>

        </div>
    )
}

export default NavCandles