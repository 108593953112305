import React from 'react'
import _ from 'lodash'

import {
    getDefaultWallets,
    RainbowKitProvider,
    darkTheme, 
    Theme,
    ConnectButton
  } from '@rainbow-me/rainbowkit'
  import { configureChains, createConfig, WagmiConfig } from 'wagmi'

  import {
    mainnet
  } from 'wagmi/chains'

import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'

const { chains, publicClient } = configureChains(
    [mainnet],
    [
      alchemyProvider({ apiKey: "Hh8fdQZL2nYcS0fYVKslLvSoIZM7Ml9L" }),
      publicProvider()
    ]
  )
  
const { connectors } = getDefaultWallets({
    appName: 'Wandering Witches',
    projectId: '4989eadbb562e805ebb2da103a6897bc',
    chains
})
  
const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient
})

import logo from "../img/logo.png"

import discord from '../img/discord.png'
import etherscan from '../img/etherscan.png'
import instagram from '../img/instagram.png'
import opensea from '../img/opensea.png'
import twitter from '../img/twitter.png'

import tarot from '../img/tarot.png'
import dirt from '../img/dirt.png'

import witchEns0 from '../img/witch-ens0.png'
import witchFumeiji from '../img/witch-fumeiji.png'

import potionRed from '../img/potion-red.png'
import potionPurple from '../img/potion-purple.png'

import NavCandles from './NavCandles'
import MintView from './MintView'
import SettingView from './SettingView'

const theme = _.merge(darkTheme(), {
    fonts: {
        body: "Patrick Hand SC"
    },
    radii: {
        connectButton: "9999px"
    },
    colors: {
        connectButtonText: "#FCF0DA"
    }
} as Theme)

const WebApp = () => {
    return(
        <WagmiConfig config={wagmiConfig}>
            <RainbowKitProvider chains={chains} theme={theme}>
                <div>
                    <div className="mt-48 mb-16 mx-auto w-9/12">
                        <div className="mx-auto w-6/12 text-center">
                            <a title="Twitter" rel="noopener" href="https://twitter.com/wandwitchnft" target="_blank"><img className="w-[36px] mx-1 inline-block" src={twitter} alt="Twitter" /></a>
                            <a title="Instagram" rel="noopener" href="https://www.instagram.com/wandwitchnft/" target="_blank"><img className="w-[36px] mx-1 inline-block" src={instagram} alt="Instagram" /></a>
                            <a title="Etherscan" rel="noopener" href="https://etherscan.io/address/0x6d2bbdb4aa635370ef95025e26b764f3666c3566" target="_blank"><img className="w-[36px] mx-1 inline-block" src={etherscan} alt="Etherscan" /></a>
                            <a title="OpenSea" rel="noopener" href="https://opensea.io/collection/wandering-witches" target="_blank"><img className="w-[36px] mx-1 inline-block" src={opensea} alt="OpenSea" /></a>
                            <a title="Discord" rel="noopener" href="https://discord.gg/6nzuDZ3aT6" target="_blank"><img className="w-[36px] mx-1 inline-block" src={discord} alt="Discord" /></a>
                        </div>

                        <img src={logo} alt="Wandering Witches" />

                        <div className="mt-[-20px] mx-auto text-center text-lg">A PROJECT BY SMOL FARM</div>
                    </div>

                    <NavCandles />

                    <div className="flex justify-center mt-24">
                        <ConnectButton chainStatus="icon" accountStatus="address" />
                    </div>
                    
                    <div className="mt-16 mx-auto mb-16 w-9/12 text-3xl text-center">
                        your eyes become heavy as you gaze into the flickering light<br />
                        who will you become as you venture into the whispering realm?
                    </div>

                    <MintView />

                    <div className="about">
                        <h2 id="about" className="text-6xl mt-48 mb-6 mx-auto text-center">about</h2>

                        <p className="mt-16 text-2xl w-6/12 mx-auto text-center">
                            Innumerable witches wander the whispering realms, but Beakon’s ritual enable us to bind 5,622 to an ethereal token. Each ERC721 token includes unique artwork depicting the witch, a magical metadata scan of their biography, and access to mystifying experiences deeper in the whispering realms.
                        </p>
                    </div>

                    <div className="w-9/12 mx-auto h-[500px] mt-[-100px]">
                        <img src={potionRed} className="w-[400px] float-right rotate-45" />
                    </div>

                    <h2 id="path" className="text-6xl mt-8 mb-6 mx-auto text-center">the path</h2>

                    <div className="mt-16 text-3xl w-6/12 mx-auto text-center">
                        <p className="mb-6">We aren't here to make false promises about what we can deliver. The only way we will successfully accomplish these goals will be due to the full funding of this project (aka, minting out). Keeping our home paid for will be the FIRST priority, as we are both unemployed and rely on income via NFT creation.</p> 

                        <p>However, it will be easy for us to accomplish everything in our roadmap when we don't have to worry about keeping our roof over our heads, and if we have the capital to fund the work + materials that go into the development of these things.</p>
                    </div>

                    <h3 className="text-4xl mt-48 mb-6 mx-auto text-center">dream journal</h3>

                    <div className="mt-12 text-2xl w-6/12 mx-auto text-center">
                        weave tales of your wandering into the ether, immortalizing your slumbering journeys. from publicly posted memories, the WANDWITCHES, as a community, will select foci of power to be crafted into a new collection.
                    </div>

                    <h3 className="text-4xl mt-16 mb-6 mx-auto text-center">tarot</h3>

                    <div className="mt-12 text-2xl w-6/12 mx-auto text-center">
                        <div className="inline-block w-[100%] lg:w-[70%] p-6 align-top">
                            <p className="mb-6">much as wandering witches can project themselves through space, they can peer through time with TAROT. physical decks will be crafted for dissemination through our physical realm.</p>

                            <p>a mystifying digital experience will provide a reading through virtual cards. the digital readings can then be minted as soulbound tokens on an L2, to keep the consumption of rare reagents minimal.</p>
                        </div>

                        <div className="inline-block p-3 w-[100%] lg:w-[30%]">
                            <img src={tarot} />
                        </div>
                    </div>

                    <h3 className="text-4xl mt-16 mb-6 mx-auto text-center">short stories</h3>

                    <div className="mt-12 text-2xl w-6/12 mx-auto text-center">
                        short tales about the whispering realms will be published on Mirror as collectible Optimism tokens, though available for all to read for free forever

                        <br /><br /><br />

                        <a target="_new" href="https://mirror.xyz/smolfarm.eth/MhlgeDG8UbGCPqA_MYMCVttItEq2d6pEuI6Zix9h364" className="border-4 border-[#FBF0DC] hover:border-white text-2xl lg:text-xl font-bold py-4 px-6 m-2 rounded-full">Sakura's Slumbering Saga</a>
                    </div>

                    <h3 className="text-4xl mt-16 mb-6 mx-auto text-center">novella</h3>

                    <div className="mt-12 text-2xl w-6/12 mx-auto text-center">
                        the tale of several witches will be written into a NOVELLA, crafted with community input during the creative process and made available in digital form for all who wander. depending on demand, physical binding of the book can be explored.
                    </div>

                    <h3 className="text-4xl mt-16 mb-6 mx-auto text-center">awakened witches</h3>

                    <div className="mt-12 text-2xl w-6/12 mx-auto text-center">
                        every witch may create an AWAKENED WITCH with heightened powers based on the original design by permanently consuming a focus of power. each awakened witch will exist as a new, separate token, so awakened forms may themselves wander find home in new places.
                    </div>

                    <h3 className="text-4xl mt-16 mb-6 mx-auto text-center">game</h3>

                    <div className="mt-12 mb-16 text-2xl w-6/12 mx-auto text-center">
                        a story-driven GAME will be developed featuring the witches, with a peculiar portal that can bring witches — merely wandering or awakened — into the game. game art direction and design will be carefully crafted by project artist fumeiji.
                    </div>

                    <img src={dirt} className="w-6/12 mx-auto my-6" />

                    <h2 id="lore" className="text-6xl mt-16 mb-6 mx-auto text-center">lore</h2>

                    <div className="mt-16 text-2xl w-6/12 mx-auto text-center">
                        <p className="mb-6">Witches who wander are not lost, whispers of their wishes calling their drowsy, dreaming minds. To slumber is to slip into a mysterious realm that connects all sentience in the cracks and crevices between infinite worlds. Yet the fog and fear that envelops this ever-shifting space scatters us into the void, doomed to create quickly collapsing realities that disappear even from our memories upon return to the waking world.</p>

                        <p className="mb-6">But there are some to whom the darkness whispers — for whom the light of day does not provide the nourishment they need. With practice and persistence, the worlds magically made by their unconscious mind be saved, shaped, and shared. However, one not need to fully give into the darkness, for Beakon guides them.</p>

                        <p className="mb-6">When restlessness overcomes a sleeping seeker’s heart, their unseeing eyes are drawn towards her light. To approach it at first is to feel scorched and seared. After all, to be fueled with passion and light the night, one must first themselves be set alight by the Promethean flame. But the touch of the goddess will live in them forever, awakening whenever they slumber.</p>
                    </div>

                    <div className="w-9/12 mx-auto mt-[-120px] h-[300px]">
                        <img src={potionPurple} className="w-[250px] float-left -rotate-45" />
                    </div>

                    <SettingView />

                    <h2 id="acolytes" className="text-6xl mt-48 mb-6 mx-auto text-center">the acolytes of beakon</h2>

                    <div className="mt-16 text-2xl w-8/12 mx-auto text-center">
                        <div className="inline-block w-[50%] p-4 text-2xl">
                            <img src={witchFumeiji} className="mb-2" />
                            Fumeiji<br />
                            <a href="https://twitter.com/fumeiji" target="_blank"><img src={twitter} className="w-[36px] mx-auto" /></a>
                        </div>
                        <div className="inline-block w-[50%] p-4 text-2xl">
                            <img src={witchEns0} className="mb-2" />
                            Ens0<br />
                            <a href="https://twitter.com/existentialenso" target="_blank"><img src={twitter} className="w-[36px] mx-auto" /></a>
                        </div>
                    </div>

                    <div className="mt-12 mb-64 text-2xl w-6/12 mx-auto text-center">
                        The acolytes of beakon serve as additional guides for the wandering witches. ens0 wields various technologies to assist the wandering witches, as well as weaving words to further guide them. fumeiji conjures visuals to aid them on their journies. together, they work with beakon to ensure that the whispering realms are safe and welcoming to all witches. 
                    </div>
                </div>
            </RainbowKitProvider>
        </WagmiConfig>
    )
}

export default WebApp