import React, { useState } from 'react'

import fields from '../img/settings-fields.png'
import caves from '../img/settings-caves.png'
import seaFloor from '../img/settings-seafloor.png'
import woods from '../img/settings-woods.png'
import manor from '../img/settings-manor.png'
import boundary from '../img/settings-boundary.png'
import library from '../img/settings-library.png'

const settings = [
    {
        name: "THE FIELDS",
        description: "Wandering at night can provide a path forward not visible during the day. To have hope about what is just over the horizon, one must first be able to see the horizon itself. The fecund fields, free of obstruction, provide unburdened, unbounded directions to explore.",
        imgSrc: fields
    },
    {
        name: "THE CAVES",
        description: "Stalactites and stalagmites may look like fearsome jaws, but they are just minerals — slowly accumulated by the flow of water. Often, wandering may seem aimless, pointless, merely accumulating dirt and grime. But, over time, it can grow into something resilient and sharp. And, after all, one can wander in wonder for centuries in a mere night with enough determination of dreaming spirit.",
        imgSrc: caves
    },
    {
        name: "THE SEA FLOOR",
        description: "Nothing is a more terrifying feeling than drowning. But the deep dark depths can provide a strangely soothing world to explore, where only wand and anglerfish may light your path. Mere mortals haul tanks of the air above just to see what you can see while your lungs breathe of the air of the room of your similarly shadowy slumber.",
        imgSrc: seaFloor
    },
    {
        name: "THE WOODS",
        description: "Under the canopy of oak and pine, the light only licks what may try to thrive underneath. While one redwood yawns, whole dynasties of kings born and die in blood. Still, under these seemingly eternal slumbering giants, herbs and mushrooms of unimaginable power thrive. Often, what may feel like missing puzzle pieces lie just off the path, pluckable for the plucky, nested in an unremarkable tuft of fallen leaves and fern.",
        imgSrc: woods
    },
    {
        name: "THE MANOR",
        description: "Most of us have a home. Many of us do not feel *at* home in it — be it due to the physical structure or those who reside within it. In the manor, you will never feel at home, but you may leave better understanding what you wish awaited you. However, it is also known to be haunted by shades of your deepest desires, whose whispers take form when spirits scrutinize one's sense of sanctuary.",
        imgSrc: manor
    },
    {
        name: "THE BOUNDARY",
        description: "Only through a fence's existence may the grass appear greener. Boundaries are not just tools of limitation, they are tools of power that let it gather and grow, to put armor between defenseless fowl and their ferocious foes But that does not mean that all boundaries should remain unbroken. Cracked concrete and rotting lumber can give way to unleash one’s potential out into the reaches it once could never cross.",
        imgSrc: boundary
    },
    {
        name: "THE LIBRARY",
        description: "In each library lives a throng of tomes so numerous we could never read them all. Yet each book is itself a portal to another world whose reaches witches may, in turn, wander — a fractal dimensional doorway through which even those without the power of project may journey.",
        imgSrc: library
    }
]

/**
 * Component to enable the user to scroll between different settings in the Whispering Realms
 */
const SettingView = () => {
    const [index, setIndex] = useState(0)

    const { name, description, imgSrc } = settings[index]

    const prev = () => {
        let newIndex = index-1
        if(newIndex < 0)
            newIndex = settings.length-1

        setIndex(newIndex)
    }

    const next = () => {
        let newIndex = index+1
        if(newIndex > settings.length-1)
            newIndex = 0

        setIndex(newIndex)
    }

    return(
        <div className="w-6/12 mx-auto text-center mt-32">
            <h2 className="text-4xl">WHISPERING REALMS</h2>

            <div className="py-16">
                <button type="button" className="float-left m-6 text-6xl mt-36" onClick={prev}>&lt;</button>
                <button type="button" className="float-right m-6 text-6xl mt-36" onClick={next}>&gt;</button>

                <img src={imgSrc} className="h-[420px] m-auto" alt={name} />
            </div>

            <h3 className="text-2xl mt-4">{name}</h3>

            <div className="text-xl my-6">
                {description}
            </div>
        </div>
    )
}

export default SettingView